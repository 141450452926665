.bandera-beneficios{
    position: absolute;
    right: 24%;
}

.bandera-beneficios-home{
    position: absolute;
    width: 40px !important;
    height: 41px !important;
    top: 1%;
    right: 1%;
}

.bandera-listas{
    position: absolute;
    right: 20%;
    top: 60%;
    z-index: 999;
}

.bandera-listas-home{
    position: absolute;
    right: 5%;
    top: 75%;
    z-index: 999;
}

@media (max-width: 768px) { 
    .bandera-beneficios{
        position: absolute;
        right: 40%;
    }
    
    .bandera-beneficios-home{
        right: 8%;
    }
    
    .bandera-listas{
        position: absolute;
        top: 59%;
    } 
    .bandera-listas-home{
        right: 10%;
    }
}