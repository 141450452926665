@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.quienes-somos{
    .text{
        font: 400 2rem / 2.2rem 'HelveticaRoundedCd';
        color: $semilightergray;
        &-blue{
            color: $paleblue;
        }
    }
    img{
        width: 100%;
    }
}