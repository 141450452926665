.pan{padding:0    !important}  .pln,.phn{padding-left:0    !important}  .prn,.phn{padding-right:0    !important}  .pbn,.pvn{padding-bottom:0    !important}  .ptn,.pvn{padding-top:0    !important}
.paxs{padding:5px  !important}.plxs,.phxs{padding-left:5px  !important}.prxs,.phxs{padding-right:5px  !important}.pbxs,.pvxs{padding-bottom:5px  !important}.ptxs,.pvxs{padding-top:5px  !important}
 .pas{padding:10px !important}  .pls,.phs{padding-left:10px !important}  .prs,.phs{padding-right:10px !important}  .pbs,.pvs{padding-bottom:10px !important}  .pts,.pvs{padding-top:10px !important}
 .pam{padding:15px !important}  .plm,.phm{padding-left:15px !important}  .prm,.phm{padding-right:15px !important}  .pbm,.pvm{padding-bottom:15px !important}  .ptm,.pvm{padding-top:15px !important}
 .pal{padding:20px !important}  .pll,.phl{padding-left:20px !important}  .prl,.phl{padding-right:20px !important}  .pbl,.pvl{padding-bottom:20px !important}  .ptl,.pvl{padding-top:20px !important}
.paxl{padding:30px !important}.plxl,.phwl{padding-left:30px !important}.prxl,.phxl{padding-right:30px !important}.pbxl,.pvxl{padding-bottom:30px !important}.ptxl,.pvxl{padding-top:30px !important}
 .man{ margin:0    !important}  .mln,.mhn{ margin-left:0    !important}  .mrn,.mhn{ margin-right:0    !important}  .mbn,.mvn{ margin-bottom:0    !important}  .mtn,.mvn{ margin-top:0    !important}
.maxs{ margin:5px  !important}.mlxs,.mhxs{ margin-left:5px  !important}.mrxs,.mhxs{ margin-right:5px  !important}.mbxs,.mvxs{ margin-bottom:5px  !important}.mtxs,.mvxs{ margin-top:5px  !important}
 .mas{ margin:10px !important}  .mls,.mhs{ margin-left:10px !important}  .mrs,.mhs{ margin-right:10px !important}  .mbs,.mvs{ margin-bottom:10px !important}  .mts,.mvs{ margin-top:10px !important}
 .mam{ margin:15px !important}  .mlm,.mhm{ margin-left:15px !important}  .mrm,.mhm{ margin-right:15px !important}  .mbm,.mvm{ margin-bottom:15px !important}  .mtm,.mvm{ margin-top:15px !important}
 .mal{ margin:20px !important}  .mll,.mhl{ margin-left:20px !important}  .mrl,.mhl{ margin-right:20px !important}  .mbl,.mvl{ margin-bottom:20px !important}  .mtl,.mvl{ margin-top:20px !important}
.maxl{ margin:30px !important}.mlxl,.mhwl{ margin-left:30px !important}.mrxl,.mhxl{ margin-right:30px !important}.mbxl,.mvxl{ margin-bottom:30px !important}.mtxl,.mvxl{ margin-top:30px !important}
