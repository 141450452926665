@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.thumbnail{
    &_img{
        height: 181px !important;
    }
    cursor: pointer;
}

.thumb-testimonios{
    padding: 12px 12px 29px 12px;
    -webkit-box-shadow: 3px 4px 10px 0px rgba(0,0,0,0.66);
-moz-box-shadow: 3px 4px 10px 0px rgba(0,0,0,0.66);
box-shadow: 3px 4px 10px 0px rgba(0,0,0,0.66);
     -webkit-transform:rotate(352deg);
    -moz-transform:rotate(352deg);
    -o-transform:rotate(352deg);
    transform:rotate(352deg);
    border: 1px solid #fff;
    -webkit-border-radius: 1px;
-moz-border-radius: 1px;
border-radius: 1px;
}