@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.ayuda{
    &_subtitle{
        font: 400 2.9rem / 3.0rem 'BebasNeue';
        color: $paleblue;
        display: inline-block;
        vertical-align: middle;
        &_img{
           vertical-align: middle; 
        }
    }
    &_collapse{
        &_panel{
            border: none;
            &_heading{
                border-top-right-radius: 6px;
                border-top-left-radius: 6px;
            }
            &_title{
                &-text{
                    text-decoration: none;
                    color: $white;
                    font: 400 1.6rem / 3rem 'Helvetica';
                    height: 30px;
                }
                &-arrow{
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
            &-odd{
                .ayuda_collapse_panel_heading{
                    background-color: $lightgray;
                }    
                .ayuda_collapse_panel_title-text{
                    color: $midgray;
                }
                .ayuda_collapse_panel_title-arrow{
                    color: $semilightgray;
                }
            }
            &-even{
                .ayuda_collapse_panel_heading{
                    background-color: $cyan;
                }   
                .ayuda_collapse_panel_title-text{
                    color: $white;
                }
                .ayuda_collapse_panel_title-arrow{
                    color: $white;
                }
            }
        }
        &_body{
            border: none;
            padding: 40px;
        }
        &_li{
            margin-bottom: 30px;
            font: 700 1.3rem / 1.4rem 'HelveticaRounded';
            color: $semilightgray;
            &-color{
                color:$cyan;
            }
            &_a{
                text-decoration: none;
                color: $cyan;
            }
        }
    }
    &_contact{
        width: 100%;
        height: 50px;
        border: 1px solid $lightgray;
        border-radius: 5px;
        background-color: $whitegray;
        font: 400 1.8rem / 1.9rem 'Helvetica';
        color: $semilightergray;
        text-decoration: none;
        text-align: center;
        &-color{
            color: $cyan;
        }
    }
}
/******** sobrepaso las propiedades que ya tiene con este selector*********/
i.ayuda_collapse_panel_title-arrow.hidden-xs.ayuda_collapse_panel_title-arrow-up.fa.fa-caret-up.fa-2x{
    display:none;
}
