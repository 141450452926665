@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.bootbox-confirm, .bootbox-alert{
    .bootbox-body{
        font: 700 2rem / 2.6rem 'HelveticaRoundedCd';
        color: $semilightergray;
    }
    .modal-footer{
        .btn-default{
            background-color: $semilightergray;
            border-color: $semilightergray;
            -webkit-box-shadow: -5px 5px 4px 0px rgba(177, 175, 175, 1);
            -moz-box-shadow:    -5px 5px 4px 0px rgba(177, 175, 175, 1);
            box-shadow:         -5px 5px 4px 0px rgba(177, 175, 175, 1);
            &:hover{
            color: $semilightergray;
            background-color: $white;
            color: $semilightergray;
            }   
            &:focus{
                color: $semilightergray;
                background-color: $white;
                color: $semilightergray;
            }
            &:active{
                color: $semilightergray !important;
                background-color: $white !important;
                color: $semilightergray !important;
            }
        }
        .btn-primary{
            margin-left: 15px;
            background-color: $green;
            border-color: $green;
            -webkit-box-shadow: -5px 5px 4px 0px rgba(177, 175, 175, 1);
            -moz-box-shadow:    -5px 5px 4px 0px rgba(177, 175, 175, 1);
            box-shadow:         -5px 5px 4px 0px rgba(177, 175, 175, 1);
            &:hover{
                color: $green;
                background-color: $white;
                color: $green;
            }   
            &:focus{
                color: $green;
                background-color: $white;
                color: $green;
            } 
            &:active{
                color: $green !important;
                background-color: $white !important;
                color: $green !important;
            }
        }
    }
}