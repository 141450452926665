@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.preguntas {
    &-alignment{
        display: inline-block;
        text-align: left;
    }
}