@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";
@import "../components/user-profile";

.micuenta{
    &-active{
        width: 97px;
        height: 19px;
        background-color: $cyan;
        color: $white;
        font: 400 1rem / 1.5rem 'HelveticaRounded';
        border-radius: 4px;
        text-align: center;
        padding: 3px;
        display: none;
        &.active{
            display: inline-block;
        }
    }
    .col-height{
        height: 145px;
        background-color: $whitegray;
        padding: 35px 0;
    }
    &_number{
        font: 400 3.8rem / 5.3rem 'HelveticaRounded';
        color: $cyan;
    }
    &_subtitle{
        font: 100 1.5rem / 2.4rem 'HelveticaRounded';
        color: $semilightergray;
    }
    &_table{
        &_heading{
            font: 100 0.9rem / 1rem 'HelveticaRounded';
            color: $black;
        }
        &_info{
            font: 100 1rem / 1rem 'HelveticaRounded';
            color: $midgray;
        }
        &_button{
            font-size: 1rem;
            width: 100%;
            min-height: 100%;
            background-color: $white;
            &:hover{
                background-color: $lightgray;
            }
            i{
                &.i-green{
                    color: $green;
                }
                &.i-cyan{
                    color: $cyan;
                }
                &.i-lightgray{
                    color: $semilightgray;
                }
            }
        }
    }
    /*****collapsables*****/
    .panel-heading{
        color: $cyan;
        background-color: $white;  
    }
    .panel-default{
        border-color: $cyan;
    }
}

//VIDEOS

.videos{
    iframe{
        width: 300px;
        height: 170px;
    }
}
.videos-table{
    th, td{
        font-size: 1.4rem !important;
    }

}

.regalo-seleccionado{
    >td{
        border-top: 2px dashed $cyan !important;
        border-bottom: 2px dashed $cyan !important;
        >span,>a{
            color: $cyan !important;
        }
    }
}