@import "../libs/bootstrap-sass/assets/stylesheets/bootstrap";
@import "customvariables";
@import "../libs/owl.carousel/src/scss/owl.carousel";
@import "../libs/font-awesome/css/font-awesome.min.css";
@import "../libs/bootstrap-select/sass/bootstrap-select";


@import "reset";
@import "fonts";
@import "mixins";
@import "variables";
@import "margin&padding";
@import "components/formularios";
@import "components/buttons";
@import "components/modals";
@import "components/select-alistate";
@import "components/google-map";
@import "components/filtrocheckbox&radio";
@import "components/thumbnail";
@import "components/modal-misvideos";
@import "components/login";
@import "components/banderas";
@import "pages/header";
@import "pages/footer";
@import "pages/home";
@import "pages/beneficios";
@import "pages/micuenta";
@import "pages/giftlist";
@import "pages/sucursales";
@import "pages/ayuda";
@import "pages/actualizarperfil";
@import "pages/prueba";
@import "pages/contacto";
@import "pages/quienessomos";
@import "pages/prensa";
@import "pages/regalar";
@import "pages/crearlista";
@import "pages/comofunciona";
@import "pages/formaspago";
@import "pages/preguntas";
@import "pages/confirmarregalo";
@import "pages/evento";
@import "pages/notifications";
@import "pages/galeria";
@import "pages/invitaramigos";
@import "pages/testimonios";

/*
HTML
HEADER
FOOTER
MEDIAQUERY
*/

/****HTML****/
html{
    font-size: 62.5%;
    font-family: 'Helvetica';
    body{
        padding-top: 40px;
    }
    h1{
        font: 400 3.9rem / 5rem 'BebasNeue';
        color: $paleblue;
    }
    h2{
        font: 100 3rem / 4rem 'Helvetica';
        color: $cyan;
    }
    h3{
        font: 400 2.4rem / 2.4rem 'SueEllenFrancisco';
        color: $paleblue;
    }
    h4{
        font: 100 2.6rem / 3.3rem 'HelveticaRounded';
        color: $paleblue;
    }
    h5{
        font: 700 2rem / 2.6rem 'HelveticaRoundedCd';
        color: $semilightergray;
    }
    label{
        font: 400 1.5rem / 2.0rem 'Helvetica';
        color: $semilightergray;
    }
    p.help-block{
        font: 400 1rem / 1.4rem 'Helvetica';
        color: $darkgray;
    }
    .p-helvetica{
        text-decoration: none;
        color: $semilightergray;
        font: 400 1.6rem / 1.9rem 'Helvetica';
    }
}
a{
    text-decoration: none !important;
}
.text-cyan{
    color: $cyan !important; 
}
.cursiva-grande{
    font: 400 2.4rem / 2.9rem 'SueEllenFrancisco';
    color: $paleblue;
}
.truncatecharts{
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}
.activar-cuenta-p{
    >p{
    font-size: 14px;
    color: $midgray;
        font-weight: 400;
    line-height: 17px;
    font-family: 'Helvetica';
    min-height: 153px;}
}

.media-medios-pagos{
    h4{
        font-family: 'HelveticaRounded';
        color:  #3677bc;
        font-size: 24px;/* Aproximación debida a la sustitución de la fuente */
        font-weight: 700;
        line-height: 27.764px;/* Aproximación debida a la sustitución de la fuente */
        text-align: left;
    }
    .media-body{
    padding-left: 6%;    
    font-family: 'HelveticaNeue';
    color:  #595d67;
    font-size: 16px;/* Aproximación debida a la sustitución de la fuente */
    line-height: 18.895px;/* Aproximación debida a la sustitución de la fuente */
        text-align: left;}
}

@media (max-width: 768px) {
    .modal-content2{
        padding: 0 0;
    }
    .modal-body2{
        padding: 0 !important;
        }
}
/****HEADER****/
header{
    @include header;
}
/****FOOTER****/
footer{
    @include footer;
}
/****MEDIAQUERY****/
 @include bp-large;
 @include bp-small;
 @include bp-medium;
