@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

@mixin footer{
    .col{
        &-border{
            height:145px;
            border-right: 1px solid $lightgray;
        }
    }
    .li{
        &_title{
            font: 700 1.5rem / 2.0rem 'HelveticaRounded';
            color:$lightgray;
        }
        &_item{
            margin-top: 3px;
            a{
                text-decoration: none;
                font: 400 1.5rem / 1.6rem 'Helvetica';
            }
        }
    }
    .footer_span{
        color: $semilightergray;
        font: 400 1.2rem / 1.6rem 'Helvetica';
    }
    .footer_img{
        display: inline;
        width: 64px;
        height: 20px;
    }
}

.mails-internacionales{
    background-color: $darkgray;
    padding: 1.6% 0;
    a{
        font-family: HelveticaRounded;
        color:  #ffffff;
        font-size: 17px;/* Aproximación debida a la sustitución de la fuente */
        font-weight: 400;
        line-height: 46.545px;/* Aproximación debida a la sustitución de la fuente */
        text-align: left;
        padding-right: 10px;
        img{
            vertical-align: middle;
            width: 25px;
        }
    }
}