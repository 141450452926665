@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.regalar{
    &_bgimg{
        background: url(/static/images/keybord.jpg);
        background-size: cover;
        padding: 110px 200px;
        .search{
            &_input{
                height: 68px;
                position:relative;
                padding: 13px 30px;
                font: 400 2.3rem / 2.6rem 'Arial';
                color: $midgray;
                border-radius: 0;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    font: 400 2.3rem / 3.3rem 'Arial';
                    color: $midgray;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                    font: 400 2.3rem / 3.3rem 'Arial';
                    color: $midgray;
                }
                &::-ms-input-placeholder { /* IE 10+ */
                    font: 400 2.3rem / 3.3rem 'Arial';
                    color: $midgray;
                }
                &::-moz-placeholder { /* Firefox 18- */
                    font: 400 2.3rem / 3.5rem 'Arial';
                    color: $midgray;
                }
            }
            &_btn{
                position: absolute;
                top: 2px;
                right: 40px;
                font: 100 5rem / 5rem 'BebasNeue';
                color: $midgray;
                background-color: $white;
                border-color: $white;
                &:active{
                    color: $midgray;
                    background-color: $white;
                    border-color: $white;
                }
            }
            &_p{
                background-color: $paleblue;
                font: 400 1.5rem / 1.5rem 'Helvetica';
                word-spacing: 3px;
                color: $white;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                text-align: center;
                padding: 6px 10px;
            }
        }
    }
    &_btn{
        background-color: $paleblue;
        color: $white;
        border-color: $paleblue;
        width: 89px;
        margin-right: 10px;
        &.active{
            background-color: $palered;
            border-color: $palered;
        }
    }
    &_user{
        &_container{
            padding: 15px;
            max-width: 250px;
            margin: 0 auto;
            border: 1px solid $lightgray;
            border-radius: 4px;
            height: 320px;
        }
        &_a{
            position: relative;
            display: block;
            &:hover{
                background-color: $whitegray;
            }

        }
        &_img{
            border: 5px solid $lightgray;
            max-width: 200px;      
            margin: 0 auto;
            display: block;
            height: 200px;
        }
        &_date{
            font: 1.4rem / 1.4rem 'HelveticaNeue';
            color: $semilightergray;
        }
        &_name{
            font:700 2.1rem / 2.1rem 'HelveticaRoundedCd';
        }
    }
    &_alllist{
        width: 100%;
        height: 40px;
        padding-top: 8px;
        padding-bottom: 8px;
        text-align: center;
        border: 1px solid $lightgray;
        border-radius: 5px;
        background-color: $whitegray;
        font: 400 1.8rem / 1.9rem 'Helvetica';
        color: $semilightergray;
        text-align: center;
        z-index: -1;
        &-cyan{
            color: $cyan;
        }
        &_img{
            margin-bottom: -20px;
            z-index: 1;
            position:relative;
            display: inline-block;
        } 
    }
}
