.box {
    display:inline-block;
    cursor: pointer;
    height: 182px;
    margin: 5px;
    position: relative;
    overflow:hidden;
    width: 200px;
}
 
.box img {
    position: absolute;
    display:block;
    left: 0;
    width: 200px;
    height:182px; 
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    -ms-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
}
.box .caption {
    opacity: 0;
    position: absolute;
    z-index: 100;
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    -ms-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
    left: 0;
}
#box-5.box .fade-caption {
    background-color: rgba(63,63,63,0.6);
    font: 100 1.5rem / 1.6rem 'HelveticaNeue';
    color:$white;
    width: 200px;
    height: 200px;
    text-align: center;
    padding-top: 30px;
    top: 0;
    opacity: 0;
}
#box-5.box:hover .fade-caption {
    opacity: 1;
}
#box-5.box:hover img {
    opacity: 0;
}

/* Grow */
.hvr-grow {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
    transform: scale(1.1);
}

