@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.benefits{
    .filter{
        &_btn-group{
            background-color: $whitegray;
            border-radius: 5px;
            border: 1px solid $lightgray;
            padding: 9px 10px;
            min-height:44px;
            li{
                height: 28px;
                padding: 2px 10px;
                display: inline-block;
                min-width: 20px;
                vertical-align: middle;
                border-right: 1px solid $lightgray;
            }
            .arrow{
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;
                border-left: 12px solid $cyan;
                display: inline-block;
             }
            &_button{
                padding: 6px;
                font: 400 1.4rem / 1.5rem 'Helvetica';
                color: $semilightgray;
                border: none;
                background-color: $whitegray;
                height: 22px;
                border-radius: 5px;
                &.active,&:hover{
                    background-color: $semilightgray;
                    border-color:$semilightgray;
                    color: $white;
                    -webkit-box-shadow: inset 0px 0px 2px 0px rgba(50, 50, 50, 1);
                    -moz-box-shadow:    inset 0px 0px 2px 0px rgba(50, 50, 50, 1);
                    box-shadow:         inset 0px 0px 2px 0px rgba(50, 50, 50, 1);
                }
            }
            li:first-child{
                border: none;
            }
            li:last-child{
                border: none;
            }
        }
    }
    &_elements{
        &_item{
            border-bottom: 2px solid $whitegray;
            &:last-child{
                border: none;
            }
            .col{
                &-width{
                    width: 1px;
                }
                &-width2{
                    min-width: 230px;
                }
                &-wordbreak{
                    word-break: break-all;
                }
            }
                
            .divider{
                display: inline-block;
                width: 0;
                height: 175px;
                border-left: 1px solid $whitegray;
                border-right: 1px solid $whitegray;
            }
        }
    }
    
    &_img{
        border: 5px solid $whitegray;
        border-radius: 100px;
        vertical-align: middle;
        max-width: 175px;
        max-hegiht: 175px;
    }
    &_name{
        font: 100 2.7rem / 3.4rem 'BebasNeue';
        color: $paleblue;
        padding: 20px;
    }
    &_description{
        font: 100 1.2rem / 1.7rem 'HelveticaRounded';
        color: $semilightgray;
        text-align: left;
        vertical-align: middle;
        padding: 15px;
    }
    &_contact{
        li{
            width: 100%;
            margin-bottom: 10px;
            padding: 0 10px;
        }
        &_img{
            color: $cyan;
            float: left;
            margin-right: 10px; 
            width:20px;
            height: 20px;
        }
        &_p{
            overflow: hidden;
            font: 100 1.3rem / 1.8rem 'HelveticaRounded';
            color: $semilightgray;  
            vertical-align: middle;
            &-cyan{
                color: $cyan;
            }
        }
    }      
}

.no-border-categoria{
    border-right: none !important;
    padding-right: 0 !important;
}