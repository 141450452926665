@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.dz-default{
    span{
        font: 700 2rem / 2.6rem 'HelveticaRoundedCd';
        color: $semilightergray;
    }  
}
#add-photos{
    border-radius: 5px;
    border: 2px solid $cyan;
}