/* Project specific CSS goes here. */
.size-maps {
  width: 400px !important;
  height: 300px !important;
}

.size-maps-sucursal {
  width: 600px !important;
  height: 210px !important;
}

/* .modal-fullscreen */
.modal-fullscreen {
  background: transparent;
}
.modal-fullscreen .modal-content {
  background: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal-backdrop.modal-backdrop-fullscreen {
  background: #ffffff;
}
.modal-backdrop.modal-backdrop-fullscreen.in {
  opacity: .97;
  filter: alpha(opacity=97);
}

/* .modal-fullscreen size: we use Bootstrap media query breakpoints */

.modal-fullscreen .modal-dialog {
  margin: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
@media (min-width: 768px) {
  .modal-fullscreen .modal-dialog {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .modal-fullscreen .modal-dialog {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .modal-fullscreen .modal-dialog {
     width: 1170px;
  }
}


/* Absolute Center CSS Spinner */
.spinner {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.spinner:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.spinner:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  height:80px;
  width:80px;
  margin-top: -0.5em;
   -webkit-animation: rotation .6s infinite linear;
   -moz-animation: rotation .6s infinite linear;
   -o-animation: rotation .6s infinite linear;
   animation: rotation .6s infinite linear;
   border-left:6px solid rgba(0,174,239,.15);
   border-right:6px solid rgba(0,174,239,.15);
   border-bottom:6px solid rgba(0,174,239,.15);
   border-top:6px solid rgba(0,174,239,.8);
   border-radius:100%;}

/* Animation */

@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
}

/* jQuery Growl
 * Copyright 2015 Kevin Sylvestre
 * 1.3.1
 */
#growls {
  z-index: 50000;
  position: fixed; }
  #growls.default {
    top: 10px;
    right: 10px; }
  #growls.tl {
    top: 10px;
    left: 10px; }
  #growls.tr {
    top: 10px;
    right: 10px; }
  #growls.bl {
    bottom: 10px;
    left: 10px; }
  #growls.br {
    bottom: 10px;
    right: 10px; }
  #growls.tc {
    top: 10px;
    right: 10px;
    left: 10px; }
  #growls.bc {
    bottom: 10px;
    right: 10px;
    left: 10px; }
  #growls.tc .growl, #growls.bc .growl {
    margin-left: auto;
    margin-right: auto; }

.growl {
  opacity: 0.8;
  filter: alpha(opacity=80);
  position: relative;
  border-radius: 4px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  .growl.growl-incoming {
    opacity: 0;
    filter: alpha(opacity=0); }
  .growl.growl-outgoing {
    opacity: 0;
    filter: alpha(opacity=0); }
  .growl.growl-small {
    width: 200px;
    padding: 5px;
    margin: 5px; }
  .growl.growl-medium {
    width: 250px;
    padding: 10px;
    margin: 10px; }
  .growl.growl-large {
    width: 300px;
    padding: 15px;
    margin: 15px; }
  .growl.growl-xlarge {
    width: 400px;
    padding: 15px;
    margin: 15px; }
  .growl.growl-default {
    color: #FFF;
    background: #60C7CC;
    font-size: 17px;
}
  .growl.growl-error {
    color: #FFF;
    background: #CE4A64; }
  .growl.growl-notice {
    color: #FFF;
    background: #60C7CC;
    font-size: 17px;  
}
  .growl.growl-warning {
    color: #FFF;
    background: #60C7CC; }
  .growl .growl-close {
    cursor: pointer;
    float: right;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
    font-family: helvetica, verdana, sans-serif;
    padding-left: 2px;
}
  .growl .growl-title {
    font-size: 18px;
    line-height: 24px; }
  .growl .growl-message {
    font-size: 16px;
    line-height: 18px; }
