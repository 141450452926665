@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.product{
    padding: 10px;
    position: relative;
    min-height: 318px;
    &_price{
        position: absolute;
        top: 25px;
        right: 30px;
        background-color: $white;
        color: $cyan;
        border: 2px solid $cyan;
        border-radius: 3px;
        font: 100 1.7rem / 1.7rem 'HelveticaRounded';
        padding: 5px 5px 2px 5px;
    }
    &_price-2{
        border: 1px solid $cyan;
        color: $semilightergray;
        font: 400 1.7rem / 1.7rem 'HelveticaRounded';
        padding: 4px 0;
        text-align: center;
        width: 100%;
        &-total{
            border-color: $white;
            color: $white;
            background-color: $green;
        }
    }
    &_thumbnail{
        position: relative;
        margin-bottom: 0;
    }
    &_img{
        width: 100%;
        height: 250px !important;
        display: inline-block;
        &_shared{
            position: absolute;  
            top: -10px;
            left: -10px;
        }
    }
    &_title{
        font: 700 2.0rem / 2.1rem 'HelveticaRoundedCd';
        color: $paleblue;
    }
    &_subtitle{
        font: 100 1.6rem / 1.7rem 'HelveticaRounded';
        color: $midgray;
    }
    &_description{
        color: $semilightgray; 
        font: 400 1.8rem / 2rem 'BebasNeue';
        margin-top: 7px;
        word-break: break-all;
    }
    &_pricetitle{
        font: 700 1rem / 1.2rem 'HelveticaRounded';
        color: $cyan;
    }
    &:hover{
        background-color: $whitegray;
    }
}