@import "variables";


/**
    MEDIAQ
    Transitions
    Opacity
    BoxShadow
**/

/************MEDIAQ************/
@mixin bp-large {
  @media (max-width: 1200px) {
      /**header**/
      .header_desc{
            margin-right: 120px !important;
        }
    }
}

@mixin bp-medium {
    @media (max-width: 992px) {
        /**header**/
        .login{
            border: none;
        }   
        header{
            .header_centrar-menu{
                margin-left: 25%;
            }
        }
        /**footer**/
        footer{
        }
        /**home**/
        .home{
            .bg-video{
                height: 400px;
                background:url(/static/images/couple.jpg);
                background-size: cover;
                background-repeat: no-repeat;
            }
            .video{
                display:none;
            } 
            .search {
                 padding: 0 50px ;
            }
        }
        /**regalar**/
        .regalar{
            &_bgimg{
                padding: 100px 30px ;
            }
        }
        /**login**/
        .login{
            &_bg-img{
                padding: 80px 100px;
            }
        }
        /**register**/
        .register{
            &_bg-img{
                padding: 80px 100px;
            }
        }
        
    }
}

@mixin bp-small {
    @media (max-width: 768px) {
        /**general**/
        .centered-768{
            text-align: center;
            .benefits_contact_img{
                float: none;
            }
        }
        .pull-lista{
            float: none;
        }
        /**footer**/ 
        footer{
            .col{
                &-border{
                    border: none;
                }
            }
            .logo_img{
                display: block;
                margin: 0 auto;
            }
            .li{
                &_title , &_item{text-align: center;}
            }
        }
        /**home**/
        .home{
            .bg_img-blue{
                padding: 60px 0;
            }
            .current-couples{
                &_row-padding{
                    padding: 0;
                }
            }
            .support{
                .col-border{
                    border:none;
                }
            }
        }
        /**modal**/
        .modal{
            &-borderright{
                border: none;
            }
        }
        /**contacto**/
        .contacto{
            &_col-border-right{
                border-right: none;
            }
        }
        /**login**/
        .login{
            &_bg-img{
                padding: 40px !important;
            }
        }
        /**register**/
        .register{
            &_bg-img{
                padding: 40px !important;
            }
        }
    }
}
/************Transitions************/
@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}
@mixin translate($x,$y){
    @include transform(translate($x, $y));
}
/************Opacity************/
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}
/************BoxShadow*********************/
@mixin box-shadow{
    -webkit-box-shadow: 0px 5px 9px -1px rgba(50, 50, 50, 0.68);
    -moz-box-shadow:    0px 5px 9px -1px rgba(50, 50, 50, 0.68);
    box-shadow:         0px 5px 9px -1px rgba(50, 50, 50, 0.68);
}

/************CHECKBOX*********************/
@mixin checkbox($width,$height,$check-size,$colorchecked,$background-color,$border-color){
    /****** hide original******/
    input[type=checkbox] {
        display:none;
    }
    /****** create new buttons ******/
    input[type=checkbox] + label:before{
        /*** mandatory ***/
        content: "";  
        display: inline-block;  
        vertical-align:middle;
        margin-right: 8px;  
        /*** style ***/
        background-color: $background-color;  
        border: 2px solid $border-color;
        border-radius: 4px;
        width: $width;  
        height: $height;  
        cursor:pointer;
    }
    /****** check status ******/
    
    input[type=checkbox]:checked + label:before{
        /*** mandatory ***/
        content:"\2714"; /* Tick */
        text-align:center;
        vertical-align: middle;
        /*** style ***/
        color:$colorchecked;
        background-color: $background-color; 
        border: 2px solid $border-color;
        border-radius: 4px;
        font-size:$check-size;
        line-height:$check-size + 3;
    }
}
/******** RADIO ********/
@mixin radio($width,$height,$check-size,$colorchecked,$background-color,$border-color){
    /****** hide original******/
    input[type=radio]{
        display:none;
    }
    /****** create new buttons ******/
    input[type=radio] + label:before{
        /*** mandatory ***/
        content: "";  
        display: inline-block;  
        vertical-align:middle;
        margin-right: 8px;  
        /*** style ***/
        background-color: $background-color; 
        border: 3px solid $border-color;
        border-radius: 10px;
        width: $width;  
        height: $height; 
    }
    /****** check status ******/
    input[type=radio]:checked + label:before{
        /*** mandatory ***/
        content: "\2022"; /* Bullet */
        text-align:center;
        vertical-align: middle;
        /*** style ***/
        color: $colorchecked;
        background-color: $background-color; 
        border: 3px solid $border-color;
        border-radius: 10px;
        font-size:$check-size;
        line-height:$check-size;
    }
}
