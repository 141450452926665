@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.form-gris{
    input, textarea{
        background-color: $whitegray;
        border-radius: 6px !important;
        border-color: $lightgray;
        padding-top: 2px !important;
        word-break: break-all;
        margin-bottom: 7px;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            font: 400 1.6rem / 2.2rem 'HelveticaRoundedCd';
            color: $semilightgray;
            text-transform: capitalize;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            font: 400 1.6rem / 2.2rem 'HelveticaRoundedCd';
            color: $semilightgray;
            text-transform: capitalize ;     
        }
        &::-ms-input-placeholder { /* IE 10+ */
            font: 400 1.6rem / 2.2rem 'HelveticaRoundedCd';
            color: $semilightgray;
            text-transform: capitalize;
        }
        &::-moz-placeholder { /* Firefox 18- */
            font: 400 1.6rem / 2.2rem 'HelveticaRoundedCd';
            color: $semilightgray;
            text-transform: capitalize;
        }
    }
    textarea{
        height: 100px !important;
        resize: none;
    }  
}

.form-cyan{
    input, textarea{
        background-color: $white;
        border-radius: 2px !important;
        border-color: $cyan;
        padding-top: 6px !important;
        word-break: break-all;
        margin-bottom: 7px;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            font: 700 1.7rem / 1.8rem 'HelveticaRounded';
            color: $lightgray;
            text-transform: capitalize;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            font: 700 1.7rem / 1.8rem 'HelveticaRounded';
            color: $lightgray;
            text-transform: capitalize;
        }
        &::-ms-input-placeholder { /* IE 10+ */
            font: 700 1.7rem / 1.8rem 'HelveticaRounded';
            color: $lightgray;
            text-transform: capitalize;
        }
        &::-moz-placeholder { /* Firefox 18- */
            font: 700 1.7rem / 1.8rem 'HelveticaRounded';
            color: $lightgray;
            text-transform: capitalize;
        }
    }
    textarea{
        height: 100px !important;
        resize: none;
    }  
}

/***input for subir comprobante***/
.input-xxlarge{
    resize: none;
    border-color: $cyan;
}