@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.contacto{
    &_col-border-right{
        border-right: 1px solid $lightgray;
    }
    &_col-border-top{
        border-top: 1px solid $cyan;
    }
    &_ul{
        li{
            width: 100%;
            margin-bottom: 32px;
            padding: 0 10px;
        }
        span,img,i{
            color: $cyan;
            float: left;
            margin-right: 15px; 
            width:26px;
            height: 32px;
            padding-bottom: 8px;
        }
        p{
            overflow: hidden;
            font: 100 1.6rem / 1.8rem 'HelveticaRounded';
            color: $semilightgray;  
            vertical-align: middle;
        }
        a{
            &:hover{
                p{
                    color: $cyan;
                }
            }
        }
    }
    &-call{
        margin-top: -20px;
        a{
            text-decoration: none;
            width:100px;
            display: inline-block;
            background-color: $white;
        }
        &_text{
            font: 400 1.9rem / 2.1rem 'BebasNeue';
            color: $cyan;
        }
    }
}

.bandera-contaco{
    margin-top: -38px;
}