
/*********BebasNeue*************/
@font-face {
    font-family: "BebasNeue";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/BebasNeue-Bold.otf");
}
@font-face {
    font-family: "BebasNeue";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/BebasNeue-Regular.otf");
}
@font-face {
    font-family: "BebasNeue";
    font-style: normal;
    font-weight: 100;
    src: url("../fonts/BebasNeue-Thin.otf");
}
/*********Helvetica**********/
@font-face {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/HelveticaNeueLTStd-Bd.otf");
}
@font-face {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/HelveticaNeueLTStd-Md.otf");
}
@font-face {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 100;
    src: url("../fonts/HelveticaNeueLTStd-UltLt.otf");
}

/*********Helvetica Rounded*************/
@font-face {
    font-family: "HelveticaRounded";
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/HelveticaRounded-Black.otf");
}
@font-face {
    font-family: "HelveticaRounded";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/HelveticaRounded-Bold.otf");
}
@font-face {
    font-family: "HelveticaRoundedCd";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/HelveticaRounded-BoldCond.otf");
}

/*********Sue Ellen Francisco**********/
@font-face {
    font-family: "SueEllenFrancisco";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/SueEllenFrancisco.ttf");
}
