@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.invitaramigos{
    &_imagen{
        font: 400 1.3rem / 2rem 'Helvetica';
        color: $darkgray; 
    }
}