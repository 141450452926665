@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.crear-lista{
    &_p{
        font: 700 2rem / 2.6rem 'HelveticaRoundedCd' !important;
        &-2{
            font: 700 2.8rem / 2.8rem 'HelveticaRoundedCd';
            color: $lightgray;
        }
    }
    &-bg{
        background-color: $semilightergray;
    }
    &_btn{
        margin-top: -15px;
        margin-bottom: -20px;
        &:active{
            -webkit-transform: rotate(1px,1px);
        }
        &:hover{
            -webkit-transform: translate(1px,1px);
        }
    }
}
