@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.login{
    &_bg-img{
        background: url(/static/images/couple-water.jpg);
        background-size:cover;
        background-repeat: no-repeat;
        padding: 80px 300px;
    }
    &_well{
        display: block;
        background-color: rgba(237, 233, 231, 0.8);
        border-radius: 3px;
        border-width: 10px;
        border-color:rgba(167, 163, 165, 0.6);
        border-style: solid;
        padding: 20px;

    }
    &_title{
        font: 400 3.5rem / 3.5rem 'BebasNeue';
        color: $paleblue;
    }
    .socialaccount_ballot{
        font: 700 1.7rem / 4rem 'HelveticaRoundedCd';
        height:40px;
        color: $midgray;
        margin: 0 auto;
        padding-left: 20px;
        width: 210px;
    }
    .socialaccount_providers{
        display: inline-block;
    }
    &_text{
        &-1{
            float: left;
        }
        &-2{
            color: $paleblue;
            font: 700 1.6rem / 1.6rem 'HelveticaRoundedCd';
            display: inline-block;
            &-gray{
                color: $midgray;
            }
        }
    }
    &-or{
        &_hr{
            margin: 0;
            border: 1px solid $paleblue;
        }
        &_text{
            color: $paleblue;
            font: 700 1.6rem / 1.6rem 'HelveticaRoundedCd';
            margin-top: -8px;
        }
    }   
    .control-label{
        font: 700 1.5rem / 1.7rem 'HelveticaRoundedCd';
        color: $midgray;
        margin-bottom: 4px;
    }
    #div_id_remember{
        label{
            color: $paleblue;
        }
    }
    &_button{
        display: block;
        margin: 0 auto;
    }
}

.register{
    &_bg-img{
        background: url(/static/images/couple-register.png);
        background-size:cover;
        background-repeat: no-repeat;
        padding: 80px 300px;
    }
    &_button{
        display: block;
        margin: 0 auto;
        border: 2px solid $white;
        &:hover,:active,:focus{
            border: 2px solid $cyan;
        }
    }
}
textarea#id_como_conocio.textarea.form-control{
    resize: none;
}
