@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.p-conf-regalo{
    td{
        font: 400 1.3rem / 2rem 'Helvetica';
        color: $darkgray; 
        &>small{
            font-size: 1rem;
        }
    }
}
.datos-aside-conf{
    font: 400 1.3rem / 2rem 'Helvetica';
    color: $darkgray; 
    b{
        font-size: 1.5rem;
    }
}