@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.prensa{
    &_subtitle{
        font: 400 2.5rem / 2.6rem 'BebasNeue';
        color: $semilightergray;
    }
    &_article{
        width: 210px;
        margin: 0 auto;
        &:hover{
            img{
                border-color: $semilightergray;
            }
        }
        &_img{
            border: 10px solid $whitegray;
            display: block;
            height: 210px;
        }
        &_title{    
            border-bottom: 3px solid $whitegray;
            text-align: left;
            width: 100%;
            &_icon{
                border: 3px solid $whitegray;
                vertical-align: middle;
                width: 50px;
                height: 50px;
            }
            &_text{
                vertical-align: middle;
                font: 400 2.2rem / 2.2rem 'BebasNeue';
                color: $paleblue;
            }
        }
        &_contact{
            li{
                margin-bottom: 5px;
                width: 100%;
            }
            &_icon{
                float: left;
                width:20px;
                height: 20px;
                margin-right: 5px; 
            }
            &_text{
                
                font: 400 1.2rem / 2.3rem 'Helvetica';
                color: $semilightergray;
                overflow: hidden;
                vertical-align: middle;
                &-cyan{
                    color: $cyan;
                    &:hover{
                        color: $cyan !important;
                    }
                }
            }
        }
    }
    &-borderbottom{
        border-bottom: 3px solid $whitegray;
    }
    &_more{
        width: 100%;
        height: 40px;
        padding-top: 8px;
        padding-bottom: 8px;
        border: 1px solid $lightgray;
        border-radius: 5px;
        background-color: $whitegray;
        font: 400 1.8rem / 1.9rem 'Helvetica';
        color: $semilightergray;
        text-align: center;
        z-index: -1;
        &-cyan{
            color: $cyan;
        }
        &_img{
            margin-bottom: -20px;
            z-index: 1;
            position:relative;
            display: inline-block;
        }
    }  
}