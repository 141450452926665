@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.evento{
    &_text{
        font: 400 1.3rem/1.5rem 'HelveticaRoundedCd';
        color: black;
        margin-top: 4px;
        margin-bottom: 4px;
    }
}