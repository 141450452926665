@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";




/********** COLOCAR SPAN EN TODOS LOS BOTONES !!!!!! ********/
.btn{
    font: 400 1.7rem / 2rem 'HelveticaRoundedCd';
    border-radius: 5px;
    color: $white;
    padding: 2px 3px 4px 3px;
    text-transform: uppercase;
    &-success{
        background-color: $cyan;
        border-color: $cyan;
        position: relative;
        &>.tick,.back,.forward{
            margin-right: 7px;
            vertical-align: middle;
            width: 16px;
            height: 16px;
        }
        &>span{
            vertical-align: middle;
        }
         &:hover{
            color: $cyan;
            background-color: $white;
            border-color: $cyan;
        }
        &:focus{
            color: $cyan;
            background-color: $white;
            border-color: $cyan;
        }
        &:active{
            color: $cyan !important;
            background-color: $white !important;
            border-color: $cyan !important;
        }
        &-handleft{
            &>.handleft{
                position: absolute;
                top: 2px;
                left: -15px; 
            }
        }
        &-handup{
            &>.handup{
                position: absolute;
                bottom: -25px;
                right: -8px;
            }
        }
    }
    &-primary{
        background-color: $semilightergray;
        border-color: $semilightergray;
        -webkit-box-shadow: -5px 5px 4px 0px rgba(177, 175, 175, 1);
        -moz-box-shadow:    -5px 5px 4px 0px rgba(177, 175, 175, 1);
        box-shadow:         -5px 5px 4px 0px rgba(177, 175, 175, 1);
        &>.tick,.back,.forward{
            margin-right: 7px;
            vertical-align: middle;
            width: 16px;
            height: 16px;
        }
         &>span{
            vertical-align: middle;
        }
        &:hover{
            color: $semilightergray;
            background-color: $white;
            color: $semilightergray;
        }   
        &:focus{
            color: $semilightergray;
            background-color: $white;
            color: $semilightergray;
        }
        &:active{
            color: $semilightergray !important;
            background-color: $white !important;
            color: $semilightergray !important;
        }
    }
    &-default{
        background-color: $green;
        border-color: $green;
        -webkit-box-shadow: -5px 5px 4px 0px rgba(177, 175, 175, 1);
        -moz-box-shadow:    -5px 5px 4px 0px rgba(177, 175, 175, 1);
        box-shadow:         -5px 5px 4px 0px rgba(177, 175, 175, 1);
        &>.tick,.back,.forward{
            margin-right: 7px;
            vertical-align: middle;
            width: 16px;
            height: 16px;
        }
         &>span{
            vertical-align: middle;
        }
        &:hover{
            color: $green;
            background-color: $white;
            color: $green;
        }   
        &:focus{
            color: $green;
            background-color: $white;
            color: $green;
        } 
        &:active{
            color: $green !important;
            background-color: $white !important;
            color: $green !important;
        }
    }
    &-enviar{
        z-index: 3;
        background-color:$cyan;
        float:right;
        margin-top: -18px;
        margin-right: 15px;
        min-width: 100px;
       -webkit-box-shadow: 0px 43px 38px -27px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 43px 38px -27px rgba(0,0,0,0.75);
        box-shadow: 0px 43px 38px -27px rgba(0,0,0,0.75);
        &-handleft{
            &>.handleft{
                position: absolute;
                top: 2px;
                left: -15px; 
            }
        }
        &-handup{
            &>.handup{
                position: absolute;
                bottom: -25px;
                right: -8px;
            }
        }
    }
}

.select-beneficios{
    .btn-default{
        background-color: $cyan;
        color: white;
        border: 1px solid $cyan;
    }
}