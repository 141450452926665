@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.como-funciona{
    &_text{
        font: 400 1.5rem / 2.0rem 'Helvetica';
        color: $semilightgray;
    }
}

