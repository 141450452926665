@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.user-profile{
    &_img{
        max-width: 221px;
    }
    &_sections{
        text-align: left;
        color: $semilightergray;
        background-color: $white;
        width: 236px;
        padding: 5px 0;
        border-radius: 5px;
        span{
            font: 400 1.4rem / 1.4rem 'Helvetica';
        }
        img{
            vertical-align: middle;
        }
        &:hover, &.active, &:active{
            background-color: $whitegray;
            color: $semilightergray;
            border-color: $lightgray;
        }
    }
}
