.comentario-testi{
    font: 400 2.9rem/3.6rem 'SueEllenFrancisco';
    color:$paleblue;
    
}
.pareja-testi{
    color: $cyan;
    font-size: 2.5rem;
    padding: 4% 0;
}

.fecha-testi{
    font-size: 1.5rem;
    color: $darkgray;
    padding-bottom: 2%;
}

.row-testi{
    margin-bottom: 5%;
}

.hr-testi{
    border-top: 2px solid $lightgray;
}