@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.filtro-checkbox{
    @include checkbox(15px,15px,10px,$cyan,$white,$cyan);
}
.filtro-radio{
    @include radio(15px,15px,14px,$cyan,$white,$cyan);
}