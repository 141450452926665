@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.select-alistate{
    .bs-caret,.caret{
        display: none;
    }
    .btn{
        font: 400 1.3rem / 1.5rem 'HelveticaRoundedCd';
        background-color: $whitegray;
        color: $semilightgray;
        border-radius: 1px;
        border-color: $whitegray;
        text-transform: none;
        text-align: center;
    }
    /***filtros***/
    .btn-group, .multiselect{
        width: 100%;
        .btn{
            box-shadow: none;
            height: 28px;
            &:focus{
                color: $semilightgray;
                border-color: $white;
            }
            &:active{
                color: $semilightgray !important;
                border-color: $white;
            }
            span{
                width: 100%;
                text-align: center;
            }
        }
        
    /**---filtros***/
    &>.dropdown-menu{
        background-color: $whitegray;
        a{
            color: $semilightgray; 
            font: 400 1.3rem / 1.4rem 'HelveticaRoundedCd';
            background-color: $whitegray;
            &:hover{
                color: $whitegray; 
                background-color: $semilightgray; 
            }
        }
    }
}
}
//        Centra el texto del select
.filter-option{
    text-align: center !important;
    margin-left: 10px;
}

//        Modifico el hover del multiselect
.multiselect-container{
    a:hover{
        label{
            color:$white;
        }
    }
}
.select-pagar{
    height: 35px;
    button{
        height: 35px;
        span{
            font-size: 1.8rem;
            line-height: 3rem;
            height: 30px;
            &:after{
                content:" \2193";
            }
        }
    }
}




