@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

    
@mixin navbar{
    .navbar{
        background-color: $white;
        border: none;
        padding-bottom: 60px;
        margin-bottom: 0px;
//        -webkit-box-shadow: 0px -1px 68px -43px rgba(0,0,0,0.75);
//        -moz-box-shadow: 0px -1px 68px -43px rgba(0,0,0,0.75);
//        box-shadow: 0px -1px 68px -43px rgba(0,0,0,0.75);
    }
    .navbar-default{
        .navbar-toggle{
            margin-bottom: 100px;
            border-color:$paleblue;
            .icon-bar{
                color:$paleblue;
                background-color: $paleblue;
                border-color: $paleblue;
            }
        }  
        .header_desc{
            margin-top: 90px; 
            margin-right: 187px;
        }
        .logo_img{
            display: block;
            max-width: 200px;
        }
        .col-height{
            height: 0;
            .logo_margin-top{
                margin-top: -120px;
            }
        }
    }
    .navbar-collapse{
        padding-top: 20px;
        border-color: $white !important;
        .nav-links{  
            margin-top: 45px;
            vertical-align: middle;
            a{ 
                padding: 15px 0;
                margin: 0 5px;
                .login{
                    &_img{
                        display:inline-block;
                        vertical-align: middle;
                    }
                }
            }
        } 
    }
}

.login{
    border-right: 1px solid $lightgray;
    &_p{
        display: inline-block;
        font: 400 1.8rem / 2.3rem 'BebasNeue';
        color: $lightpalered !important;
        vertical-align: middle;
    }  
    &_p-2{
        display: inline-block;
        font: 400 1.8rem / 2.3rem 'BebasNeue';
        color: $paleblue !important;
        vertical-align: middle;
    }
    &_salir{
        margin-top: 7px;
    }
}
.carrito{
    position: relative;
    &:hover{
        text-decoration: none;
    }
    i,span{
        color: #ffffff;
        font-size: 2rem;
        margin-right: 9px;   
        &:hover{
            color: $cyan;
        }
    }    
    .badge-1{
        position: absolute;
        text-align: center;  
        background-color: $palered;
        color: $white;
        font-size: 1.1rem;
        top:-8px;
        right: -7px;
        display:none;
        height: 18px;
        min-width: 18px;
        line-height: 18px;  
        border-radius: 10px;
        &-show{
            display:block;
        }
    }    
}
.menu_responsive{
    
    &>li{
        border-bottom: 1px dashed $paleblue;
        text-align: center;
        &:hover{
            background-color: $whitegray;
        }
    }
}
.alert, .growl{
    font-size: 1.4rem;
    font-family: 'Helvetica';
    font-weight: 400;
}
