@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";
@import "../components/navbar";

@mixin header{
    .header{
        &_logo{
            width: 23px;
            height: 23px;
        }
        &_centrar-menu{
            margin-left: 29%;
        }
        &-bluebg{
            background-color: $paleblue;
        }
        &_link{
            font: 400 1.5rem / 1.9rem 'BebasNeue';
            color: $white;
            text-decoration: none;
            background-color: $paleblue;
            border-color: $paleblue;
            border-radius: 0px;
            padding: 3px 10px;
            margin-right: 0;
            margin-left: 0;
            &:hover{
                color: $cyan;
            }
            &-border-right{
                border-right: 2px solid $white;
            }
        }
    }
    @include navbar;
}

.login_xs{
    font: 700 2rem / 2.6rem 'HelveticaRoundedCd';
    color: $white;
    vertical-align: text-top;
}