@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";



/* para el fixed nav bar*/
.modal-backdrop{
    z-index: 100;
}
.modal-fullscreen{
    padding-top: 35px;
}
.modal{
    /******generales***/
    &-content{
         padding: 0 30px;
    }
    &-header,&-footer{
        border: none;
    }
    &-dialog-pay{
        overflow-y: auto !important;
        height: 700px;
    }
    &-title{
        &>img{
        background-color: $cyan;
        padding: 6px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        vertical-align: middle;
        display: inline-block;
        height: 34px;
        width: 34px;
        }
        &>span{
            width:90%;
            font: 700 2.8rem / 2.8rem 'BebasNeue';
            color: $cyan;
            background: $whitegray;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            display: inline-block;
            vertical-align: middle;
            padding: 5px 0px 1px 15px;
        }
    }
    &_close{
        background-color: $lightgray !important;
        border-radius: 50%;
        opacity: 1;
        color: $white;
        padding: 4px !important;
        position: relative;
        height: 25px;
        width: 25px;
        span{
            position:absolute;
            top:-1px;
            left: 25%;
        }
        &-red{
            background-color: $lightpalered !important;
            float:none;
        }
    }

    /****** particulares***/
    &-borderright{
        border-right: 1px solid $lightgray;
    }
    &-bordertop{
        border-top:  1px solid $lightgray;
    }
    &-borderleft{
        border-left:  1px solid $lightgray;
    }
    &-borderbottom{
        border-bottom:  1px solid $lightgray;
    }
    &-border{
        border: 1px solid $lightgray;
    }
    &_help{
        font:700 1.5rem / 1.6rem 'HelveticaNeue';
        color: $midgray;
        text-align: center;
    }
    &_progressbar{
        border-radius: 1px;
        background-color: $semilightergray;
        font-family: 'HelveticaRounded';
        &>.progress-bar{
             background: rgba(169,3,41,1);
            background: -moz-linear-gradient(top, rgba(169,3,41,1) 0%, rgba(112,28,49,1) 44%, rgba(117,40,59,1) 75%, rgba(163,161,162,1) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(169,3,41,1)), color-stop(44%, rgba(112,28,49,1)), color-stop(75%, rgba(117,40,59,1)), color-stop(100%, rgba(163,161,162,1)));
            background: -webkit-linear-gradient(top, rgba(169,3,41,1) 0%, rgba(112,28,49,1) 44%, rgba(117,40,59,1) 75%, rgba(163,161,162,1) 100%);
            background: -o-linear-gradient(top, rgba(169,3,41,1) 0%, rgba(112,28,49,1) 44%, rgba(117,40,59,1) 75%, rgba(163,161,162,1) 100%);
            background: -ms-linear-gradient(top, rgba(169,3,41,1) 0%, rgba(112,28,49,1) 44%, rgba(117,40,59,1) 75%, rgba(163,161,162,1) 100%);
            background: linear-gradient(to bottom, rgba(169,3,41,1) 0%, rgba(112,28,49,1) 44%, rgba(117,40,59,1) 75%, rgba(163,161,162,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a90329', endColorstr='#a3a1a2', GradientType=0 );
        }
    }
    &_text-1{
        font: 700 1.4rem / 1.4rem 'HelveticaNeue';
        color: $midgray;
    }
    &_text-2{
        font: 700 1.4rem / 1.4rem 'HelveticaNeue';
        color: $semilightgray;
        &-border{
            border: 2px solid $palered;
            padding: 3px;
            border-radius: 2px;
        }
        &-red{
            color: $palered;
        }
         &-cyan{
            color: $cyan;
        }
    }
    &_text-3{
        font: 700 1.3rem / 2.3rem 'HelveticaRounded';
        color: $paleblue;
        vertical-align: middle;
        display: inline-block;
    }
    ////table/////
    &_th{
        border: none !important;
    }
    &_td{
        display: table-cell;
        border-top: none !important;
        border-bottom: 2px solid $lightgray;
        padding: 15px 8px !important;
        vertical-align: middle !important;
    }
    &_row-graybg{
        background-color: $lightgray;
    }
}

.social{
    .gplus-this{
        a{
        color: $googleplus;}
    }
    .tweet-this{
        a{
            color: $twitter;}
    }
}

.modal-atencion{
}
