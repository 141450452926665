@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";
@import "../components/hover";

.home{
    .bg-video{
        position: relative;
        height: 576px;
    }
    .video{
        min-width: 100% ;
        height: auto;
        z-index: -100;
        background-size: cover;
        position:absolute;
        top: 0;  
    }
    .search{
        position: absolute;
        z-index: 100;
        width: 100%;
        top: 35%;
        padding: 0 200px; 
        &_input{
            height: 68px;
            position:relative;
            padding: 13px 30px;
            font: 400 2.3rem / 2.6rem 'Arial';
            color: $midgray;
            border-radius: 0;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                font: 400 2.3rem / 3.3rem 'Arial';
                color: $midgray;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                font: 400 2.3rem / 3.3rem 'Arial';
                color: $midgray;
            }
            &::-ms-input-placeholder { /* IE 10+ */
                font: 400 2.3rem / 3.3rem 'Arial';
                color: $midgray;
            }
            &::-moz-placeholder { /* Firefox 18- */
                font: 400 2.3rem / 3.5rem 'Arial';
                color: $midgray;
            }
            &:focus::-webkit-input-placeholder { color:transparent; }
            &:focus:-moz-placeholder { color:transparent; } /* Firefox 18- */
            &:focus::-moz-placeholder { color:transparent; } /* Firefox 19+ */
            &:focus:-ms-input-placeholder { color:transparent; } 
        }
        &_btn{
            position: absolute;
            top: 16px;
            right: 40px;
            width: 122px;
            height: 30px;
            font: 700 2rem / 2.6rem 'BebasNeue';
            color: $white;
            background-color: $lightpalered;
        }
        &_p{
            background-color: $midgray;
            font: 400 1.5rem / 1.5rem 'Helvetica';
            word-spacing: 3px;
            color: $white;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            text-align: center;
            padding: 6px 10px;
        }
    }
        

    .bg_img-blue{
        background: url(/static/images/blue.jpg);
        background-size:cover;
        background-repeat: no-repeat;
        padding: 60px 20px;
        &_p{
            font: 400 2.2rem / 3.0rem 'BebasNeue';
            color: $white;
        }
    }
    .bg_img-couple{
        background: url(/static/images/couple.jpg);
        background-size: cover;
        &_p{
            font: 100 3.7rem / 5.9rem 'Helvetica';
            color:$white;
        }
        .box_text{
            padding: 14px;
            font-size: 1.9rem;
            line-height: 2.3rem;
        }
    }
    
    .late-news{
        &_title{
            margin: 50px 0;
        }
        &_thumbnail{
            -webkit-box-shadow: 0px 11px 27px 0px rgba(50, 50, 50, 0.57);
            -moz-box-shadow:    0px 11px 27px 0px rgba(50, 50, 50, 0.57);
            box-shadow:         0px 11px 27px 0px rgba(50, 50, 50, 0.57);
        }
    }
    .current-couples{
        &_title{
            margin: 50px 0;
            -moz-box-shadow:    53px 53px 30px 0px rgba(50, 50, 50, 1);
        }
        &_row-padding{
            padding: 0 80px;
        }
        &_img{
            border: 2px solid $lightgray;
            &:hover{
                border: 2px solid $cyan;
            }
        }
        &_a{
            font: 400 1.9rem / 1.9rem 'Helvetica';
            color: $cyan;
            text-decoration: none;
        }
    }
    .carousel{
        background-color: $lightpalered;
        position: relative;
        &_img-arrow{
            margin-top: -2px;
        }
        &_title{
            color:$white;
        }
        &_row{
            max-height: 145px;
        }
        &_img{
            border: 5px solid $whitegray;
            border-radius: 100px;
            width: 136px;
            max-width: 136px;
            height: 141px;
            max-height: 141px;
        }
        &_arrow{
            &-left{
                position:absolute;
                top:  60px;
                right: 0;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-right:15px solid $white;
            }
            &-right{
                position:absolute;
                top:  60px;
                left: 0;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-left: 15px solid $white;
            }
        }
    }
    .support{
        background-color: $lightgray;

        &_text{
            color: $semilightgray;
            font: 100 2.7rem / 3.8rem 'Helvetica';
        }
        .col-border{
            border-right: 1px solid $semilightgray;

        }
    }
}

/*input[type='image']{
		float: right;
		@media (max-width: 768px) {
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
}*/