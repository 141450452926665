@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.sucursales{
    &_subtitle{
        font: 100 1.8rem / 2rem 'HelveticaRoundedCd';
        color: $semilightergray;
    }
    &_img{
        padding: 6px;
        border: 10px solid $whitegray;
        width: 100%;
        height: 209px;
    }
    &_location{
        font: 400 2.7rem / 2.7rem 'BebasNeue';
        color: $paleblue;
    }
    &-border-bottom{
        border-bottom: 2px solid $whitegray;
    }
    &_li{
        margin: 10px 0;
        font: 700 1.3rem / 1.5rem 'Helvetica';
        color: $semilightergray;
        i{
            color: $cyan;
            vertical-align: middle;
            margin-right: 10px;
        }
        a{
            color: $cyan;
            text-decoration: none;
        }
     
    }
    &-border-right{
        border-right: 2px solid $whitegray;
    }
}
#map , #map-2{
    width: 100%;
    height: 209px;
}