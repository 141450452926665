/****COLORS****/
$darkgray: #465065;
$midgray: #596378;
$semilightgray: #708196;
$semilightergray:#818a9e;
$lightgray:#D5D8e1;
$whitegray: #EEEEEE;
$white:#ffffff;
$black: #000000;
$cyan:#60c7cc;  
$paleblue: #3777BC;
$palered:#ce4964;
$lightpalered:#EB4A63;
$lightgreen: #79ff4d;
$green:#5bb872;
$twitter: #01bcf2;
$googleplus: #dd4b38;
