@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";

.formas-pago{
    &_title{
        font: 100 2.5rem / 2.5rem 'HelveticaRoundedCd';
        color: $paleblue;
    }
    &_desc{
        font: 400 1.5rem / 1.5rem 'Helvetica';
        color: $semilightgray;
        &-blue{
            color: $paleblue;
        }
        &-cyan{
            font: 400 1.5rem / 1.5rem 'HelveticaRounded';
            color: $cyan;
            i{
                font-size: 1.8rem;
            }
        }
    }
}