@import "../fonts";
@import "../mixins";
@import "../variables";
@import "../margin&padding";
@import "../components/product";

.giftlist{
    &_bgimg{
        background-size:cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        height: 322px;
        background-color: rgba(0, 0, 0, 0.2);
        
        &-opacity{
            
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &-margin{
        margin-top: -100px;
    }
    &_title{
        font: 400 3.6rem / 3.7rem 'BebasNeue';
        word-spacing: 8px;
        color: $white;
        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    }
    &_img{
        border: 5px solid $lightgray;
    }
    ul{
        width: 100%;
    }
    &_li{
        display: inline-block;
        text-align: center;
        margin-right: 20px;
        button{
            width: 100%; 
            height: 37px;
            color: $semilightergray;
            background-color: $white;
            border-radius: 5px;
            padding: 7px;
            &:hover,&.active,&:active{
                background-color: $semilightgray;
                color: $white;
            }
            span{
                vertical-align: baseline;
                font: 700 1.4rem / 1.4rem 'Helvetica';
            }
            img{
                vertical-align: middle;
                margin-right: 10px;
            }
        }  
    }
    
    &_search{
        &_input{
            height: 28px;
            position:relative;
            padding: 5px 25px;
            font: 400 1.5rem / 2.8rem 'Arial';
            background-color: $whitegray;
            color: $semilightgray;
            border-radius: 2px;
            border: none;
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                font: 400 1.3rem / 2.5rem 'Arial';
                color: $semilightgray;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                font: 400 1.3rem / 2.5rem 'Arial';
                color: $semilightgray;
            }
            &::-ms-input-placeholder { /* IE 10+ */
                font: 400 1.3rem / 2.5rem 'Arial';
                color: $semilightgray;
            }
            &::-moz-placeholder { /* Firefox 18- */
                font: 400 1.3rem / 2.5rem 'Arial';
                color: $semilightgray;
            }
        }
        &_btn{
            position: absolute;
            top: 0px;
            right: 30px;
            width: 40px;
            height: 28px;
            padding: 0;
            font: 700 2rem / 2rem 'HelveticaNeueRoundCdBold';
            background-color: $whitegray;
            color: $semilightgray;
            border-top: 1px solid $lightgray;
            &:active{
                box-shadow: none;
            }
        }
    }
}
